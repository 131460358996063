@use '/styles/mixin/fonts.module.scss' as *;
.drive-rating-breakdown {
  &__wrapper {
    @apply w-full;
    @apply mt-8;
    @apply mb-8;
  }
  &__title {
    @include font-heading('mobile', 'h3');
    @apply font-medium;
    @screen md {
      @include font-heading('desktop', 'h3');
    }
  }
  &__container {
    @apply w-full;
    @apply mt-4;
    @apply py-2;
    @apply pl-4;
    @apply pr-3;
    @apply bg-gray-100;
    @apply flex;
    @apply items-center;
    @apply flex-col;
    @screen md {
      @apply mt-3;
      @apply pt-3;
      @apply px-8;
    }
  }
  &__rating-wrapper {
    @apply w-full;
    @apply flex;
    @apply justify-between;
    @apply border-b;
    @apply border-gray-300;
  }
  &__car {
    @include font-heading('mobile', 'h2');
    @apply capitalize;
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }
  &__features {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply flex-wrap;
    @apply justify-center;
    @apply pr-1;
    @apply pt-2;
    @screen md {
      @apply justify-between;
      @apply pr-0;
      @apply flex-row;
    }
    @screen lg {
      @apply justify-around;
    }
    @screen xl {
      @apply justify-between;
    }
  }
  &__feature-wrapper {
    @apply w-full;
    @apply my-2;
    @screen lg {
      @apply mr-0;
      @apply ml-0;
    }
  }
  &__feature-wrapper-multi-column {
    @screen lg {
      @apply max-w-xs;
    }
  }
  &__feature-label {
    @apply m-0;
    @apply -mb-2;
    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'medium');
    }
  }
  &__feature-rating-wrapper {
    @apply flex;
    @apply items-baseline;
  }
  &__feature-rating {
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @apply m-0;
    @apply ml-5;
    @apply text-blue-dark;
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply font-semibold;
      @apply ml-4;
    }
  }

  &__link-wrapper {
    @apply w-full;
    @apply flex;
    @apply justify-start;
    @apply pb-4;
    @apply pt-4;
    @screen md {
      @apply pt-0;
    }
  }
  &__link {
    @apply pt-2;
    @apply flex;
    @apply items-center;
    @include font-subtext('mobile', 'medium');
    @screen md {
      @apply pt-0;
      @include font-subtext('desktop', 'medium');
    }
  }
  &__link-icon {
    @apply ml-2;
    @apply text-xl;
  }
}

.drive-rating-breakdown__progress-bar {
  &__wrapper {
    @apply w-10/12;
    @apply bg-gray-300;
    @apply rounded-full;
  }
  &__div {
    @apply bg-black;
    @apply overflow-hidden;
    @apply rounded-l-full;
    @apply pt-2;
    @apply bg-blue-dark;
    @screen md {
      @apply pt-3;
    }
  }

  &__incomplete {
    @apply relative;
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-bottom: 0.75rem solid #e3e3e3;
      position: absolute;
      right: -0.813rem;
      bottom: 0;
      margin: 0 auto;
      z-index: 10;
    }
  }

  &__full {
    @apply rounded-r-full;
  }
}

.drive-rating-breakdown__meta {
  &__title {
    @apply text-gray-500;
    @apply text-sm;
  }
  &__link-wrapper {
    @apply text-gray-400;
    @apply text-sm;
    @apply flex;
  }
  &__label {
    @apply mr-1;
  }
  &__link {
    @apply text-gray-400;
    @apply flex;
    @apply underline;
  }
  &__price-link {
    @apply mt-6;
  }
}
