@use '/styles/mixin/fonts.module.scss' as *;
.drive-review__multicar-rating__breakdown {
  &__wrapper {
    @apply pt-5;
    @apply pb-8;
    @screen md {
      @apply pt-10;
    }
  }

  &__heading {
    @apply mb-2;
    @include font-heading('mobile', 'h4');
    @screen md {
      @include font-heading('desktop', 'h2');
      @apply mb-8;
    }
  }

  &__link-wrapper {
    @apply my-4;
    @apply inline-block;
  }

  &__link {
    @apply font-semibold;
  }
}

.drive-review__multicar-rating__overall {
  &__wrapper {
    @apply px-2;
    @apply w-full;

    @screen md {
      @apply w-1/2;
    }

    @apply mb-4;
  }

  &__cols {
    @screen lg {
      @apply w-1/2;
    }
  }

  &__cols-odd {
    @screen lg {
      @apply w-1/3;
    }
  }

  &__container {
    @apply bg-gray-100;
    @apply flex;
    @apply p-4;
    @apply items-center;
    @apply justify-between;
    @apply relative;
    @apply h-20;

    @screen md {
      @apply h-28;
    }
  }

  &__winner {
    @apply absolute;
    @apply right-0;
    top: -1.813rem;
  }

  &__winner-title {
    @apply uppercase;
    @apply bg-blue-dark;
    @apply text-white;
    @apply py-2;
    @apply px-3;
    @include font-label;
  }

  &__car {
    @apply mr-2;
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply font-semibold;
    }
  }

  &__rating-desktop {
    @apply hidden;

    @screen md {
      @include font-heading('desktop', 'h1');
      @apply inline-flex;
    }
  }

  &__rating-mobile {
    @include font-heading('mobile', 'h2');
    @screen md {
      @apply hidden;
    }
  }
}

.drive-review__multicar-rating__overall-cards {
  @apply flex;
  @apply flex-wrap;
  @apply flex-col;
  @apply -mx-2;

  @screen md {
    @apply flex-row;
    @apply items-end;
  }
}

.drive-review__multicar-rating__overall-rating {
  &__wrapper {
    @apply pt-5;

    @screen md {
      @apply pb-10;
    }
  }

  &__heading {
    @apply mb-6;

    @include font-heading('mobile', 'h4');
    @screen md {
      @include font-heading('mobile', 'h1');
      @apply mb-16;
    }
  }
}

.drive-review__multicar-rating-card {
  &__name {
    @apply mb-0;
    @apply flex-wrap;
    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'medium');
    }
  }

  &__wrapper {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply -mt-2;
  }

  &__progress-bar {
    @apply mr-4;
    @apply relative;
  }
}

.drive-review__multicar-rating-cards {
  &__wrapper {
    @apply flex;
    @apply flex-wrap;
    @apply flex-col;
    @screen md {
      @apply flex-row;
    }
    @apply -mx-2;

    .meter {
      @apply relative;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 0.75rem solid transparent;
        border-right: 0.75rem solid transparent;
        border-bottom: 0.75rem solid gray;
        position: absolute;
        right: -0.813rem;
        bottom: 0;
        margin: 0 auto;
        z-index: 10;
      }
    }
  }

  &__container {
    @apply w-full;
    @apply px-2;
    @apply mb-4;

    @screen md {
      @apply w-1/2;
      @apply mb-6;
    }

    @screen lg {
      @apply w-1/3;
    }
  }

  &__item {
    @apply bg-gray-100;
    @apply py-2;
    @apply px-4;

    @screen md {
      @apply py-4;
      @apply px-6;
    }
  }

  &__category {
    @include font-subtext('mobile', 'large');
    @apply mb-4;
    @screen md {
      @include font-subtext('desktop', 'large');
    }
  }
}
